var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"table-content"},[_c('el-table',{ref:"multipleTable",staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"tooltip-effect":"dark"},on:{"selection-change":_vm.handleSelectionChange,"row-click":_vm.rowClick}},[_c('el-table-column',{attrs:{"prop":"activ_status","sortable":"","align":"center","formatter":_vm.formatter,"label":"状态","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.activ_status == 1)?_c('div',{staticClass:"state",staticStyle:{"color":"#28b58c"}},[_vm._v(" 正在进行 ")]):_vm._e(),(scope.row.activ_status == -2)?_c('div',{staticClass:"state",staticStyle:{"color":"#f15153"}},[_vm._v(" 已结束 ")]):_vm._e(),(scope.row.activ_status == -1)?_c('div',{staticClass:"state",staticStyle:{"color":"#909399"}},[_vm._v(" 未开始 ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"title","label":"名称","width":"300","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"align":"center","prop":"diff_title","label":"难度","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[(scope.row.diff_title)?_c('div',{staticClass:"nandu",style:({
                background: scope.row.diff_color
                  ? scope.row.diff_color
                  : '#00957e',
              })},[_vm._v(" "+_vm._s(scope.row.diff_title)+" ")]):_c('div',{staticStyle:{"color":"#000"}},[_vm._v("--")])])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"类型"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"type",staticStyle:{"justify-content":"center"}},[_c('div',{staticClass:"type_left"},[_c('div',{style:({
                  background: scope.row.type_color
                    ? scope.row.type_color
                    : '#00957e',
                })},[_vm._v(" "+_vm._s(scope.row.type_str)+" ")])]),(scope.row.match_title)?_c('div',{staticClass:"type_right"},[(scope.row.match_title)?_c('div',{style:({
                  background: scope.row.match_color
                    ? scope.row.match_color
                    : '#00957e',
                })},[_vm._v(" "+_vm._s(scope.row.match_title)+" ")]):_vm._e()]):_vm._e()])]}}])}),_c('el-table-column',{attrs:{"prop":"question_num","align":"center","sortable":"","label":"题数"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"topicNum"},[_vm._v(_vm._s(scope.row.question_num)+"题")])]}}])}),_c('el-table-column',{attrs:{"prop":"activ_time","align":"center","sortable":"","label":"时间"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_c('div',[_vm._v(" "+_vm._s(scope.row.activ_time ? scope.row.activ_time : "--")+" ")])])]}}])}),_c('el-table-column',{attrs:{"prop":"is_open_str","align":"center","label":"权限"}}),_c('el-table-column',{attrs:{"prop":"group_name","align":"center","label":"举办者","show-overflow-tooltip":""}})],1)],1),_c('div',{staticClass:"dialogVisible"},[_c('el-dialog',{attrs:{"title":"","visible":_vm.dialogVisible,"width":"900px","before-close":_vm.handleClose,"close-on-click-modal":false,"show-close":false,"label-width":"80px"},on:{"update:visible":function($event){_vm.dialogVisible=$event},"open":_vm.open,"close":_vm.dialogClose}},[_c('div',{staticClass:"header"},[_c('span',[_vm._v("创建")]),_c('i',{staticClass:"el-icon-circle-close",on:{"click":function($event){_vm.dialogVisible = false}}})]),_c('div',{staticClass:"form"},[_c('form-create',{ref:"ruleForm",staticClass:"demo-ruleForm",attrs:{"label-width":"100px"},model:{value:(_vm.ruleForm),callback:function ($$v) {_vm.ruleForm=$$v},expression:"ruleForm"}},[_c('el-form-item',{attrs:{"label":"名称","prop":"name","rules":[
              { required: true, message: '请输入名称', trigger: 'blur' },
            ]}},[_c('el-input',{attrs:{"placeholder":"请输入名称"},model:{value:(_vm.ruleForm.name),callback:function ($$v) {_vm.$set(_vm.ruleForm, "name", $$v)},expression:"ruleForm.name"}})],1),_c('div',{staticStyle:{"display":"flex"}},[_c('el-form-item',{attrs:{"label":"类型","prop":"type","rules":[
                { required: true, message: '请选择类型', trigger: 'blur' },
              ]}},[_c('diy-form-item',_vm._b({attrs:{"item-type":0,"disabled":_vm.typeDisabled},on:{"update":function($event){(($event.actValue == 3 && $event.oldValue != 3) ||
                    ($event.actValue != 3 && $event.oldValue == 3)) &&
                    (_vm.ruleForm.matchType = null)}},model:{value:(_vm.ruleForm.type),callback:function ($$v) {_vm.$set(_vm.ruleForm, "type", $$v)},expression:"ruleForm.type"}},'diy-form-item',{
                  label: '类型',
                  prop: 'type',
                  type: 'select',
                  data: {
                    name: 'question_other',
                    keys: 'type',
                    isAll: false,
                  },
                },false)),(_vm.typeDisabled)?_c('div',{staticClass:"form-item-notice"},[_vm._v(" 学生只能创建比赛 ")]):_vm._e()],1),_c('el-form-item',{attrs:{"label":"公开程度","prop":"jurisdiction","rules":[
                { required: true, message: '请选择权限', trigger: 'blur' },
              ]}},[_c('el-select',{attrs:{"placeholder":"请选择权限"},model:{value:(_vm.ruleForm.jurisdiction),callback:function ($$v) {_vm.$set(_vm.ruleForm, "jurisdiction", $$v)},expression:"ruleForm.jurisdiction"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)],1),(_vm.ruleForm.jurisdiction == 0)?_c('el-form-item',{attrs:{"label":"团队","prop":"team"}},[_c('diy-form-item',_vm._b({attrs:{"item-type":0},model:{value:(_vm.ruleForm.team),callback:function ($$v) {_vm.$set(_vm.ruleForm, "team", $$v)},expression:"ruleForm.team"}},'diy-form-item',{
                label: '团队',
                prop: 'team',
                multiple: true,
                type: 'select',
                data: {
                  url: '/xapi/user.group/list',
                  params: { type: 2 },
                  labelOptions: { value: 'id', label: 'name' },
                },
              },false))],1):_vm._e(),_c('div',{staticStyle:{"display":"flex"}},[(_vm.ruleForm.type == 3)?_c('el-form-item',{attrs:{"label":"作业类型","prop":"matchType","rules":[
                {
                  required: true,
                  message: '请选择作业类型',
                  trigger: 'blur',
                },
              ]}},[_c('diy-form-item',_vm._b({attrs:{"item-type":0},model:{value:(_vm.ruleForm.matchType),callback:function ($$v) {_vm.$set(_vm.ruleForm, "matchType", $$v)},expression:"ruleForm.matchType"}},'diy-form-item',{
                  label: '作业类型',
                  prop: 'matchType',
                  type: 'select',
                  data: {
                    url: '/api/v1_0_0.question_other/options/key/match/type/3',
                    isAll: false,
                  },
                },false))],1):_vm._e(),(_vm.ruleForm.type == 2)?_c('el-form-item',{attrs:{"label":"考试类型","prop":"property","rules":[
                { required: true, message: '请选择权限', trigger: 'blur' },
              ]}},[_c('el-select',{attrs:{"placeholder":"请选择考试类型"},model:{value:(_vm.ruleForm.property),callback:function ($$v) {_vm.$set(_vm.ruleForm, "property", $$v)},expression:"ruleForm.property"}},_vm._l((_vm.ksoptions),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1):_vm._e(),(_vm.ruleForm.type == 1)?_c('el-form-item',{attrs:{"label":"赛制","prop":"matchType","rules":[
                { required: true, message: '请选择赛制', trigger: 'blur' },
              ]}},[_c('diy-form-item',_vm._b({attrs:{"item-type":0},model:{value:(_vm.ruleForm.matchType),callback:function ($$v) {_vm.$set(_vm.ruleForm, "matchType", $$v)},expression:"ruleForm.matchType"}},'diy-form-item',{
                  label: '赛制',
                  prop: 'matchType',
                  type: 'select',
                  data: {
                    name: 'question_other',
                    keys: 'match',
                    isAll: false,
                  },
                },false))],1):_vm._e(),_c('el-form-item',{attrs:{"label":"难度","prop":"diffType","rules":[
                { required: true, message: '请选择难度', trigger: 'blur' },
              ]}},[_c('diy-form-item',_vm._b({attrs:{"item-type":0},model:{value:(_vm.ruleForm.diffType),callback:function ($$v) {_vm.$set(_vm.ruleForm, "diffType", $$v)},expression:"ruleForm.diffType"}},'diy-form-item',{
                  label: '难度',
                  prop: 'diffType',
                  type: 'select',
                  data: {
                    name: 'question_other',
                    keys: 'diff_id',
                    isAll: false,
                  },
                },false))],1)],1),_c('el-form-item',{attrs:{"label":"时间限制","prop":"duration"}},[_c('el-col',{attrs:{"span":8}},[_c('el-form-item',{attrs:{"prop":"hour"}},[_c('el-input',{attrs:{"placeholder":"请输入","type":"number"},on:{"change":function($event){_vm.ruleForm.duration =
                      Number(_vm.hour || 0) * 3600 + Number(_vm.minute || 0) * 60}},model:{value:(_vm.hour),callback:function ($$v) {_vm.hour=$$v},expression:"hour"}},[_c('template',{slot:"append"},[_vm._v("小时")])],2)],1)],1),_c('el-col',{attrs:{"span":8,"offset":1}},[_c('el-form-item',{attrs:{"prop":"minute"}},[_c('el-input',{attrs:{"placeholder":"请输入","type":"number"},on:{"change":function($event){_vm.ruleForm.duration =
                      Number(_vm.hour || 0) * 3600 + Number(_vm.minute || 0) * 60}},model:{value:(_vm.minute),callback:function ($$v) {_vm.minute=$$v},expression:"minute"}},[_c('template',{slot:"append"},[_vm._v("分钟")])],2)],1)],1)],1),_c('el-form-item',{staticStyle:{"width":"60%"},attrs:{"label":"时间","prop":"active_time","rules":[
              { required: true, message: '请选择时间', trigger: 'blur' },
            ]}},[_c('div',{staticStyle:{"display":"flex"}},[_c('el-date-picker',{staticStyle:{"margin-right":"10px"},attrs:{"picker-options":_vm.pickerOptions0,"type":"date","value-format":"yyyy-MM-dd","placeholder":"开始日期"},on:{"change":function($event){_vm.ruleForm.active_time =
                    _vm.ruleForm.start_time[0] && _vm.ruleForm.end_time[0]
                      ? '1'
                      : null}},model:{value:(_vm.ruleForm.start_time[0]),callback:function ($$v) {_vm.$set(_vm.ruleForm.start_time, 0, $$v)},expression:"ruleForm.start_time[0]"}}),_c('el-time-picker',{attrs:{"placeholder":"开始时间点","value-format":"HH:mm:ss"},model:{value:(_vm.ruleForm.start_time[1]),callback:function ($$v) {_vm.$set(_vm.ruleForm.start_time, 1, $$v)},expression:"ruleForm.start_time[1]"}})],1),_c('div',{staticStyle:{"display":"flex"}},[_c('el-date-picker',{staticStyle:{"margin-right":"10px"},attrs:{"picker-options":_vm.pickerOptions1,"type":"date","placeholder":"结束日期","value-format":"yyyy-MM-dd"},on:{"change":function($event){_vm.ruleForm.active_time =
                    _vm.ruleForm.start_time[0] && _vm.ruleForm.end_time[0]
                      ? '1'
                      : null}},model:{value:(_vm.ruleForm.end_time[0]),callback:function ($$v) {_vm.$set(_vm.ruleForm.end_time, 0, $$v)},expression:"ruleForm.end_time[0]"}}),_c('el-time-picker',{attrs:{"picker-options":{
                  selectableRange: `${_vm.ruleForm.start_time[1]} - 23:59:59'`,
                },"placeholder":"结束时间点","value-format":"HH:mm:ss"},model:{value:(_vm.ruleForm.end_time[1]),callback:function ($$v) {_vm.$set(_vm.ruleForm.end_time, 1, $$v)},expression:"ruleForm.end_time[1]"}})],1)]),(_vm.ruleForm.type != 3)?_c('el-form-item',{staticStyle:{"width":"60%"},attrs:{"label":"成绩发布时间","prop":"release_time"}},[_c('div',{staticStyle:{"display":"flex"}},[_c('el-date-picker',{staticStyle:{"margin-right":"10px"},attrs:{"type":"datetime","value-format":"yyyy-MM-dd HH:mm:ss","placeholder":"开始日期"},model:{value:(_vm.ruleForm.release_time),callback:function ($$v) {_vm.$set(_vm.ruleForm, "release_time", $$v)},expression:"ruleForm.release_time"}})],1)]):_vm._e(),_c('el-form-item',{attrs:{"label":"上传图片"}},[_c('el-upload',{class:{ hide: _vm.ruleForm.fileList.length === 1 },attrs:{"action":_vm.action,"limit":1,"on-preview":_vm.handlePictureCardPreview,"on-remove":_vm.handleRemove,"on-success":_vm.success,"list-type":"picture-card"}},[_c('i',{staticClass:"el-icon-plus"},[_c('p',[_vm._v("上传图片")])])]),_c('div',{staticClass:"hint1"},[_vm._v("请上传1280*170尺寸的图片")]),_c('el-dialog',{attrs:{"visible":_vm.dialogVisibles},on:{"update:visible":function($event){_vm.dialogVisibles=$event}}},[_c('img',{attrs:{"width":"100%","src":_vm.dialogImageUrl,"alt":""}})])],1),_c('div',{staticClass:"rich"},[_c('div',{staticClass:"choice"},[(_vm.tidan_title)?_c('p',{staticClass:"tidan_title"},[_vm._v(_vm._s(_vm.tidan_title))]):_vm._e(),_c('div',{staticClass:"choice-btn",style:({
                  backgroundColor: _vm.is_select == 1 ? '#ccc' : '#00957e',
                }),attrs:{"disabled":_vm.is_select == 1},on:{"click":function($event){_vm.subject_show = true}}},[_vm._v(" 选择题单 ")]),_c('div',{staticClass:"choice-btn",style:({
                  backgroundColor: _vm.is_select == 2 ? '#ccc' : '#00957e',
                }),attrs:{"disabled":_vm.is_select == 2},on:{"click":_vm.importJob}},[_vm._v(" 选择题目 ")]),(_vm.is_select == 1)?_c('div',{staticClass:"topic_table"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.questionIds,"height":"400"}},[_c('el-table-column',{attrs:{"prop":"id","label":"题号","align":"center","width":"70"}}),_c('el-table-column',{attrs:{"prop":"type_str","label":"类型","align":"center"}}),_c('el-table-column',{attrs:{"prop":"title","label":"名称","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('span',{domProps:{"innerHTML":_vm._s(row.title)}})]}}],null,false,267524449)})],1)],1):_vm._e()])]),_c('el-form-item',{attrs:{"label":"描述","prop":"describe","rules":[
              { required: true, message: '请输入描述', trigger: 'blur' },
            ]}},[_c('diy-form-item',_vm._b({attrs:{"item-type":0},model:{value:(_vm.ruleForm.describe),callback:function ($$v) {_vm.$set(_vm.ruleForm, "describe", $$v)},expression:"ruleForm.describe"}},'diy-form-item',{
                prop: 'describe',
                label: '描述',
                type: 'editor',
                editorConfig: {
                  module: 'default',
                  initialFrameHeight: 280,
                  maximumWords: 10000,
                },
              },false))],1),_c('el-form-item',[_c('div',{staticClass:"btns"},[_c('div',{on:{"click":function($event){$event.stopPropagation();return _vm.submitForm('ruleForm', 2)}}},[_vm._v("保存")]),_c('div',{staticStyle:{"min-width":"94px","font-size":"14px"},on:{"click":function($event){$event.stopPropagation();return _vm.submitForm('ruleForm', 1)}}},[_vm._v(" 保存并发布 ")])])])],1)],1)])],1),_c('ImportJob',{ref:"importJob",attrs:{"state":_vm.state},on:{"questionIdsEvent":_vm.questionIdsEvent}}),_c('el-dialog',{attrs:{"title":"","visible":_vm.subject_show,"width":"1200px","top":"6vh","show-close":false},on:{"update:visible":function($event){_vm.subject_show=$event}}},[_c('div',{staticClass:"header"},[_c('span',[_vm._v("选择题单")]),_c('i',{staticClass:"el-icon-circle-close",on:{"click":function($event){_vm.subject_show = false}}})]),_c('div',{staticClass:"body"},[_c('el-row',{staticClass:"body-item",attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":3}},[_c('div',{},[_vm._v("搜索题单")])]),_c('el-col',{attrs:{"span":21}},[_c('el-input',{staticStyle:{"width":"48%"},attrs:{"placeholder":"搜索题单名称","size":"small"},on:{"input":_vm.searchTopic},model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}})],1)],1),_c('el-row',{staticClass:"body-item-table",attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":3}},[_c('div',{},[_vm._v("选择题单")])]),_c('el-col',{attrs:{"span":21}},[_c('ProblemListTable',{ref:"ProblemListTable",attrs:{"operation":_vm.operationTable,"isChoice":true},on:{"rowSubject":_vm.rowSubject}}),_c('div',{staticClass:"pagination"},[_c('el-pagination',{attrs:{"background":"","layout":"sizes,prev, pager, next","total":_vm.operationTable.total,"page-size":_vm.page_size,"current-page":_vm.page,"hide-on-single-page":true},on:{"current-change":_vm.currentChange}})],1)],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }