<!-- 比赛列表 -->
<template>
  <div class="">
    <div class="table-content" v-loading="loading">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        @row-click="rowClick"
      >
        <el-table-column
          prop="activ_status"
          sortable
          align="center"
          :formatter="formatter"
          label="状态"
          width="80"
        >
          <template slot-scope="scope">
            <div
              class="state"
              style="color: #28b58c"
              v-if="scope.row.activ_status == 1"
            >
              正在进行
            </div>
            <div
              class="state"
              style="color: #f15153"
              v-if="scope.row.activ_status == -2"
            >
              已结束
            </div>
            <div
              class="state"
              style="color: #909399"
              v-if="scope.row.activ_status == -1"
            >
              未开始
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="title"
          label="名称"
          width="300"
          show-overflow-tooltip
        >
        </el-table-column>

        <el-table-column align="center" prop="diff_title" label="难度" sortable>
          <template slot-scope="scope">
            <div>
              <div
                class="nandu"
                :style="{
                  background: scope.row.diff_color
                    ? scope.row.diff_color
                    : '#00957e',
                }"
                v-if="scope.row.diff_title"
              >
                {{ scope.row.diff_title }}
              </div>
              <div v-else style="color: #000">--</div>
            </div>

            <!-- <div class="type">
              <div class="type_left" style="justify-content: center;">
                <div :style="{ background: scope.row.diff_color?scope.row.diff_color:'#00957e' }" v-if="scope.row.diff_title">
                  {{ scope.row.diff_title }}
                </div>
                <div v-else style="color:#000">--</div>
              </div> -->

            <!-- </div> -->
          </template>
        </el-table-column>
        <el-table-column align="center" label="类型">
          <template slot-scope="scope">
            <div class="type" style="justify-content: center">
              <div class="type_left">
                <div
                  :style="{
                    background: scope.row.type_color
                      ? scope.row.type_color
                      : '#00957e',
                  }"
                >
                  {{ scope.row.type_str }}
                </div>
              </div>
              <div class="type_right" v-if="scope.row.match_title">
                <div
                  :style="{
                    background: scope.row.match_color
                      ? scope.row.match_color
                      : '#00957e',
                  }"
                  v-if="scope.row.match_title"
                >
                  {{ scope.row.match_title }}
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="question_num"
          align="center"
          sortable
          label="题数"
        >
          <template slot-scope="scope">
            <div class="topicNum">{{ scope.row.question_num }}题</div>
          </template>
        </el-table-column>
        <el-table-column prop="activ_time" align="center" sortable label="时间">
          <template slot-scope="scope">
            <div>
              <div>
                {{ scope.row.activ_time ? scope.row.activ_time : "--" }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="is_open_str" align="center" label="权限">
        </el-table-column>
        <el-table-column
          prop="group_name"
          align="center"
          label="举办者"
          show-overflow-tooltip
        >
        </el-table-column>
        <!-- <el-table-column prop="sign_num" align="center" label="报名人数" width="60" v-if="params.match_type">
        </el-table-column>
        <el-table-column prop="actual_sign_num" align="center" label="实际参加人数" width="70" v-if="params.match_type">
        </el-table-column> -->
      </el-table>
    </div>

    <!-- 弹框 -->
    <div class="dialogVisible">
      <el-dialog
        title=""
        :visible.sync="dialogVisible"
        width="900px"
        :before-close="handleClose"
        :close-on-click-modal="false"
        :show-close="false"
        label-width="80px"
        @open="open"
        @close="dialogClose"
      >
        <div class="header">
          <span>创建</span>
          <i class="el-icon-circle-close" @click="dialogVisible = false"></i>
        </div>
        <div class="form">
          <form-create
            v-model="ruleForm"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item
              label="名称"
              prop="name"
              :rules="[
                { required: true, message: '请输入名称', trigger: 'blur' },
              ]"
            >
              <el-input
                v-model="ruleForm.name"
                placeholder="请输入名称"
              ></el-input>
            </el-form-item>
            <div style="display: flex">
              <el-form-item
                label="类型"
                prop="type"
                :rules="[
                  { required: true, message: '请选择类型', trigger: 'blur' },
                ]"
              >
                <diy-form-item
                  :item-type="0"
                  v-model="ruleForm.type"
                  v-bind="{
                    label: '类型',
                    prop: 'type',
                    type: 'select',
                    data: {
                      name: 'question_other',
                      keys: 'type',
                      isAll: false,
                    },
                  }"
                  :disabled="typeDisabled"
                  @update="
                    (($event.actValue == 3 && $event.oldValue != 3) ||
                      ($event.actValue != 3 && $event.oldValue == 3)) &&
                      (ruleForm.matchType = null)
                  "
                >
                </diy-form-item>
                <div class="form-item-notice" v-if="typeDisabled">
                  学生只能创建比赛
                </div>
              </el-form-item>

              <el-form-item
                label="公开程度"
                prop="jurisdiction"
                :rules="[
                  { required: true, message: '请选择权限', trigger: 'blur' },
                ]"
              >
                <el-select
                  v-model="ruleForm.jurisdiction"
                  placeholder="请选择权限"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <!-- <el-form-item label="团队" prop="team" v-if="!typeDisabled || (typeDisabled && ruleForm.jurisdiction == 0)"> -->
            <el-form-item
              label="团队"
              prop="team"
              v-if="ruleForm.jurisdiction == 0"
            >
              <diy-form-item
                :item-type="0"
                v-model="ruleForm.team"
                v-bind="{
                  label: '团队',
                  prop: 'team',
                  multiple: true,
                  type: 'select',
                  data: {
                    url: '/xapi/user.group/list',
                    params: { type: 2 },
                    labelOptions: { value: 'id', label: 'name' },
                  },
                }"
              >
              </diy-form-item>
            </el-form-item>
            <div style="display: flex">
              <el-form-item
                label="作业类型"
                prop="matchType"
                v-if="ruleForm.type == 3"
                :rules="[
                  {
                    required: true,
                    message: '请选择作业类型',
                    trigger: 'blur',
                  },
                ]"
              >
                <diy-form-item
                  :item-type="0"
                  v-model="ruleForm.matchType"
                  v-bind="{
                    label: '作业类型',
                    prop: 'matchType',
                    type: 'select',
                    data: {
                      url: '/api/v1_0_0.question_other/options/key/match/type/3',
                      isAll: false,
                    },
                  }"
                >
                </diy-form-item>
              </el-form-item>
              <el-form-item
                label="考试类型"
                prop="property"
                v-if="ruleForm.type == 2"
                :rules="[
                  { required: true, message: '请选择权限', trigger: 'blur' },
                ]"
              >
                <el-select
                  v-model="ruleForm.property"
                  placeholder="请选择考试类型"
                >
                  <el-option
                    v-for="item in ksoptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="赛制"
                prop="matchType"
                v-if="ruleForm.type == 1"
                :rules="[
                  { required: true, message: '请选择赛制', trigger: 'blur' },
                ]"
              >
                <diy-form-item
                  :item-type="0"
                  v-model="ruleForm.matchType"
                  v-bind="{
                    label: '赛制',
                    prop: 'matchType',
                    type: 'select',
                    data: {
                      name: 'question_other',
                      keys: 'match',
                      isAll: false,
                    },
                  }"
                >
                </diy-form-item>
              </el-form-item>
              <el-form-item
                label="难度"
                prop="diffType"
                :rules="[
                  { required: true, message: '请选择难度', trigger: 'blur' },
                ]"
              >
                <diy-form-item
                  :item-type="0"
                  v-model="ruleForm.diffType"
                  v-bind="{
                    label: '难度',
                    prop: 'diffType',
                    type: 'select',
                    data: {
                      name: 'question_other',
                      keys: 'diff_id',
                      isAll: false,
                    },
                  }"
                >
                </diy-form-item>
              </el-form-item>
            </div>
            <el-form-item
              label="时间限制"
              prop="duration"
            >
              <el-col :span="8">
                <el-form-item prop="hour">
                  <el-input
                    placeholder="请输入"
                    @change="
                      ruleForm.duration =
                        Number(hour || 0) * 3600 + Number(minute || 0) * 60
                    "
                    v-model="hour"
                    type="number"
                  >
                    <template slot="append">小时</template>
                  </el-input>
                </el-form-item>
              </el-col>

              <el-col :span="8" :offset="1">
                <el-form-item prop="minute">
                  <el-input
                    placeholder="请输入"
                    @change="
                      ruleForm.duration =
                        Number(hour || 0) * 3600 + Number(minute || 0) * 60
                    "
                    v-model="minute"
                    type="number"
                  >
                    <template slot="append">分钟</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-form-item>
            <el-form-item
              label="时间"
              prop="active_time"
              style="width: 60%"
              :rules="[
                { required: true, message: '请选择时间', trigger: 'blur' },
              ]"
            >
              <div style="display: flex">
                <el-date-picker
                  :picker-options="pickerOptions0"
                  v-model="ruleForm.start_time[0]"
                  @change="
                    ruleForm.active_time =
                      ruleForm.start_time[0] && ruleForm.end_time[0]
                        ? '1'
                        : null
                  "
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="开始日期"
                  style="margin-right: 10px"
                >
                </el-date-picker>
                <el-time-picker
                  v-model="ruleForm.start_time[1]"
                  placeholder="开始时间点"
                  value-format="HH:mm:ss"
                >
                </el-time-picker>
              </div>
              <div style="display: flex">
                <el-date-picker
                  :picker-options="pickerOptions1"
                  v-model="ruleForm.end_time[0]"
                  @change="
                    ruleForm.active_time =
                      ruleForm.start_time[0] && ruleForm.end_time[0]
                        ? '1'
                        : null
                  "
                  type="date"
                  placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                  style="margin-right: 10px"
                >
                </el-date-picker>
                <el-time-picker
                  :picker-options="{
                    selectableRange: `${ruleForm.start_time[1]} - 23:59:59'`,
                  }"
                  v-model="ruleForm.end_time[1]"
                  placeholder="结束时间点"
                  value-format="HH:mm:ss"
                >
                </el-time-picker>
              </div>
            </el-form-item>

            <el-form-item
              label="成绩发布时间"
              v-if="ruleForm.type != 3"
              prop="release_time"
              style="width: 60%"
            >
              <div style="display: flex">
                <el-date-picker
                  v-model="ruleForm.release_time"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="开始日期"
                  style="margin-right: 10px"
                >
                </el-date-picker>
              </div>
            </el-form-item>

            <el-form-item label="上传图片">
              <!-- <div v-if="ruleForm.fileList.length==0"> -->
              <el-upload
                :action="action"
                :class="{ hide: ruleForm.fileList.length === 1 }"
                :limit="1"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :on-success="success"
                list-type="picture-card"
              >
                <i class="el-icon-plus">
                  <p>上传图片</p>
                </i>
              </el-upload>
              <div class="hint1">请上传1280*170尺寸的图片</div>
              <!-- </div> -->
              <!-- <div v-else style="width:80px;height:80px">
                <img width="100%" :src="ruleForm.fileList[0]" alt="" />
             </div> -->
              <el-dialog :visible.sync="dialogVisibles">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </el-form-item>

            <div class="rich">
              <div class="choice">
                <p class="tidan_title" v-if="tidan_title">{{ tidan_title }}</p>
                <!-- <button @click="importJob" class="choice-btn">选择题目</button> -->
                <div
                  @click="subject_show = true"
                  class="choice-btn"
                  :disabled="is_select == 1"
                  :style="{
                    backgroundColor: is_select == 1 ? '#ccc' : '#00957e',
                  }"
                >
                  选择题单
                </div>
                <div
                  @click="importJob"
                  class="choice-btn"
                  :disabled="is_select == 2"
                  :style="{
                    backgroundColor: is_select == 2 ? '#ccc' : '#00957e',
                  }"
                >
                  选择题目
                </div>

                <!-- 选择的题目表格 -->
                <div class="topic_table" v-if="is_select == 1">
                  <el-table
                    :data="questionIds"
                    style="width: 100%"
                    height="400"
                  >
                    <el-table-column
                      prop="id"
                      label="题号"
                      align="center"
                      width="70"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="type_str"
                      label="类型"
                      align="center"
                    >
                    </el-table-column>
                    <el-table-column prop="title" label="名称" align="center">
                      <template slot-scope="{ row }"
                        ><span v-html="row.title"></span
                      ></template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
            <el-form-item
              label="描述"
              prop="describe"
              :rules="[
                { required: true, message: '请输入描述', trigger: 'blur' },
              ]"
            >
              <diy-form-item
                :item-type="0"
                v-model="ruleForm.describe"
                v-bind="{
                  prop: 'describe',
                  label: '描述',
                  type: 'editor',
                  editorConfig: {
                    module: 'default',
                    initialFrameHeight: 280,
                    maximumWords: 10000,
                  },
                }"
              ></diy-form-item>
            </el-form-item>
            <el-form-item>
              <div class="btns">
                <div @click.stop="submitForm('ruleForm', 2)">保存</div>
                <div
                  @click.stop="submitForm('ruleForm', 1)"
                  style="min-width: 94px; font-size: 14px"
                >
                  保存并发布
                </div>
              </div>
            </el-form-item>
          </form-create>
        </div>
      </el-dialog>
    </div>
    <!-- 导入作业 -->
    <ImportJob
      ref="importJob"
      :state="state"
      @questionIdsEvent="questionIdsEvent"
    ></ImportJob>
    <el-dialog
      title=""
      :visible.sync="subject_show"
      width="1200px"
      top="6vh"
      :show-close="false"
    >
      <div class="header">
        <span>选择题单</span>
        <i class="el-icon-circle-close" @click="subject_show = false"></i>
      </div>
      <div class="body">
        <el-row :gutter="20" class="body-item">
          <el-col :span="3">
            <div class="">搜索题单</div>
          </el-col>
          <el-col :span="21">
            <el-input
              style="width: 48%"
              placeholder="搜索题单名称"
              v-model="searchValue"
              @input="searchTopic"
              size="small"
            ></el-input>
          </el-col>
        </el-row>

        <el-row :gutter="20" class="body-item-table">
          <el-col :span="3">
            <div class="">选择题单</div>
          </el-col>
          <el-col :span="21">
            <ProblemListTable
              :operation="operationTable"
              ref="ProblemListTable"
              :isChoice="true"
              @rowSubject="rowSubject"
            >
            </ProblemListTable>
            <div class="pagination">
              <el-pagination
                background
                layout="sizes,prev, pager, next"
                :total="operationTable.total"
                @current-change="currentChange"
                :page-size="page_size"
                :current-page="page"
                :hide-on-single-page="true"
              >
              </el-pagination>
            </div>
          </el-col>
        </el-row>
        <!-- <div class="save-btn">
          <div class="save" @click="determine">确定</div>
        </div> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Knowledge, create, is_cat } from "@/api/match.js";
import { question_list } from "@/api/problemList.js";
import ImportJob from "../../course/components/ImportJob.vue";
import ProblemListTable from "@/components/ProblemListTable/ProblemListTable.vue";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { ImportJob, ProblemListTable },
  props: {
    loading: { type: Boolean, default: () => false },
    tableData: {
      type: Array,
      default: () => [],
    },
    typeList: {
      type: Array,
      default: () => [],
    },
    matchList: {
      type: Array,
      default: () => [],
    },
    diffList: {
      type: Array,
      default: () => [],
    },

    params: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    //这里存放数据
    return {
      pickerOptions0: {
        disabledDate: (time) => {
          if (this.ruleForm.end_time[0] != "") {
            return (
              time.getTime() > new Date(this.ruleForm.end_time[0]).getTime()
            );
          }
        },
      },

      pickerOptions1: {
        disabledDate: (time) => {
          return (
            time.getTime() <
            new Date(this.ruleForm.start_time[0]).getTime() -
              1 * 24 * 60 * 60 * 1000
          );
        },
      },
      topic_tableData: [],
      is_select: 0,
      operationTable: {
        label: "题单名称",
        isSelection: false,
        time_str: false,
        tableData: [],
        total: 0,
      },
      searchValue: "",
      subject_show: false,
      hour: "",
      minute: "",
      options: [
        {
          value: 1,
          label: "公开",
        },
        {
          value: 2,
          label: "私有",
        },
        {
          value: 0,
          label: "团队",
        },
      ],
      ksoptions: [
        {
          value: 0,
          label: "线上",
        },
        {
          value: 1,
          label: "线下",
        },
      ],
      questionIds: [],
      state: "bs",
      action: this.$store.state.action,
      dialogVisible: false,
      dialogVisibles: false,
      dialogImageUrl: "",
      types: localStorage.getItem("type"),
      typeDisabled: false,
      ruleForm: {
        name: "",
        type: "",
        matchType: "",
        jurisdiction: 1,
        property: 0,
        team: [],
        diffType: "",
        // time: [],
        start_time: [],
        end_time: [],
        release_time: "",
        fileList: [],
        describe: "",
      },
      // 题单列表
      multipleSelection: [],
      page: 1,
      page_size: 50,
      list_id: "",
      tidan_title: "",
      hideUpload: false,
    };
  },
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getSubject();
  },
  computed: {
    //图片上传1张后，隐藏上传框
    uploadDisabled() {
      return this.ruleForm.fileList.length > 0;
    },
  },
  //方法集合
  methods: {
    formatter(row, column) {
      // console.log(row,"wwwwwwwwwwww");
      // return row.address;
    },
    determine() {
      this.$parent.questionIds = this.multipleSelection;
      this.dialogVisible = false;
    },
    rowSubject(item) {
      this.questionIds = [];
      this.subject_show = false;
      this.list_id = item.id;
      this.tidan_title = item.title;
      this.is_select = 2;
    },
    questionIdsEvent(e) {
      console.log(e, "e");
      this.questionIds = e;
      this.is_select = 1;
    },
    searchTopic() {
      this.getSubject({ keyword: this.searchValue });
    },
    currentChange(e) {
      this.page = e;
      this.getSubject();
    },
    close() {
      this.$refs.importJob.close();
      this.$refs.ruleForm.resetFields();
      this.is_select = 0;
    },
    dialogClose() {
      this.dialogVisible = false;
    },
    getSubject(param = {}) {
      question_list({
        ...param,
        page: this.page,
        page_size: this.page_size,
      })
        .then(({ data }) => {
          this.operationTable.total = data.total;
          this.operationTable.tableData = data.data;
        })
        .finally(() => {});
    },
    open() {
      if (this.userType == 1) {
        this.ruleForm.type = 1;
        this.typeDisabled = true;
      }
    },
    // 题单
    importJob() {
      this.tidan_title = "";
      this.list_id = "";
      this.$refs.importJob.dialogVisible = true;
    },
    // 图片上传成功
    success(fileList) {
      this.ruleForm.fileList.push(fileList.data.file_url);
      console.log(this.ruleForm.fileList);
    },

    onEditorChange({ editor, html, text }) {
      console.log(editor, html, text);
      //获取文本值
      // html带html标签
      //text只有文字
    },

    onEditorReady() {},
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.ruleForm.fileList = [];
    },

    handlePictureCardPreview(file) {
      console.log(file, "file");
      // this.dialogImageUrl = file.url;
      this.hideUpload = true;
      this.dialogVisibles = true;
    },

    handleClose() {
      this.questionIds = [];
      this.list_id = "";
      this.tidan_title = "";
      this.dialogVisible = false;
    },

    handleSelectionChange(val) {
      console.log(val);
      //   this.multipleSelection = val;
    },

    // 点击某一项
    rowClick(e) {
      // if (!localStorage.getItem("user_token")) {
      //   return (this.$store.state.lodinShow = true)
      // }
      if (e.is_open_str != "公开" && !localStorage.getItem("user_token")) {
        //   is_cat({ id: e.id }).then(res => {
        //   this.$router.push({ name: "matchDetail"+e.type, query: { id: e.id,group_id:e.group_id} })
        // })
        return (this.$store.state.lodinShow = true);
      }
      console.log(e, "eeeeeeeee");
      // this.$router.push({ name: "matchDetail"+e.type, query: { id: e.id,group_id:e.group_id} })
      // if (e.is_open == 2 && e.user_id != localStorage.getItem('user_id')) {
      //   this.$message.warning(`当前属于私有${e.type_str}`)
      //   return
      // }
      is_cat({ id: e.id }).then((res) => {
        // this.$router.push(
        //   {
        //     name: "matchDetail" + e.type,
        //     query: { id: e.id, group_id: e.group_id },
        //   },
        // );
        this.$handleRoute(
          { id: e.id, group_id: e.group_id },
          "matchDetail" + e.type,
          "_blank"
        );
      });
    },
    // 点击保存
    clickServ() {
      console.log("000000");
    },
    // 点击并发布
    submitForm(formName, is) {
      console.log(this.ruleForm);
      this.$refs[formName].validate((form, valid, errors) => {
        if (valid) {
          if (this.ruleForm.type == 1 || this.ruleForm.type == 2) {
            if (Number(this.ruleForm.duration) <= 0)
              return this.$message({
                message: "时间限制不能小于等于0",
                type: "warning",
              });
          }
          if (!this.ruleForm.start_time.length)
            return this.$message({
              message: "请选择开始时间",
              type: "warning",
            });
          if (!this.ruleForm.end_time.length)
            return this.$message({
              message: "请选择结束时间",
              type: "warning",
            });
          if (!this.questionIds.length && !this.list_id)
            return this.$message.error("请选择题单或者题目");
          let arry = [];
          this.questionIds.map((item) => {
            arry.push(item.id);
          });
          let date = new Date();
          let y = date.getFullYear();

          console.log(this.questionIds);
          let data = this.ruleForm;

          var datas = {
            list_id: this.list_id,
            type: data.type,
            title: data.name,
            match_id: data.matchType,
            is_open: data.jurisdiction,
            property: data.property,
            diff_id: data.diffType,
            group_id: data.team,
            time_str: y,
            start_time: data.start_time.join(" "),
            end_time: data.end_time.join(" "),
            release_time: data.release_time,
            question_ids: arry,
            content: data.describe,
            images: data.fileList,
            is_publish: is,
            duration: data.duration,
          };

          // console.log(data)
          create(datas)
            .then(({ data }) => {
              // this.$parent.getMatchList()
              // this.$router.push({ path: '/match/preview', query: { id: data.id } })
              if (this.ruleForm.type == 1) {
                this.$router.push({ path: "/match/myMatchList" });
              } else if (this.ruleForm.type == 2) {
                this.$router.push({ path: "/match/myCourseList" });
              } else {
                this.$router.push({ path: "/match/myExamList" });
              }
              this.dialogVisible = false;

              this.$refs[formName].resetFields();
              this.ruleForm.fileList = [];
              if (is == 2) {
                return this.$message.success("保存成功");
              }
              this.$message.success("创建成功");
              // setTimeout(()=>{
              //   console.log(data.id,'data.id')
              //   this.$router.push({path:'/match/preview',query:{id:data.id}})
              //   this.dialogVisible = false
              // },500)

              console.log(data, "创建成功");
            })
            .catch((err) => {
              console.log(err, "创建失败");
            });
        } else if ((errors || []).length > 0) {
          this.$message({ message: errors[0].message, type: "warning" });
          console.log("error submit!!", form, valid, errors);
        }
      });
    },
    resetForm(formName) {
      console.log(formName);
      // this.$refs[formName].resetFields();
    },
    time(time) {
      let date = new Date(time);
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let M = date.getMinutes();
      M = M < 10 ? "0" + M : M;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + m + "-" + d + " " + h + ":" + M + ":" + s;
    },
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style  lang='less' scoped >
.hint1 {
  font-size: 12px;
  color: #aaa;
}
.hint {
  font-size: 12px;
  color: rgb(117, 117, 117);
  position: absolute;
  width: 110px;
  bottom: -28px;
  left: -0px;
}
.pagination {
  margin: 1rem 0;
  display: flex;
  justify-content: center;
}
::v-deep {
  .el-cascader-menu__list {
    color: #00957e;
  }

  .el-cascader-node__label {
    color: #00957e !important;
  }
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
  }
  .el-input__inner {
    // border-radius: 40px;
    border: 1px solid #d4d4d4;
    height: 30px;
    line-height: 30px;
  }
  .el-table td,
  .el-table th.is-leaf {
    border-top: 1px solid #ebeef5;
    //  border-bottom: 1px solid #EBEEF5;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #00957e;
    border-color: #00957e;
  }
  .el-cascader .el-input .el-input__inner:focus,
  .el-cascader .el-input.is-focus .el-input__inner {
    border-color: #00957e;
  }
}
.header {
  height: 35px;
  background: #00957e;
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  line-height: 35px;
  align-items: center;
  .el-icon-circle-close {
    font-size: 20px;
    cursor: pointer;
  }
}
.body {
  padding: 0 3rem 2rem 3rem;
  .body-item-table {
    margin-top: 1.5rem;
  }
  .body-item {
    display: flex;
    align-items: center;
    margin-top: 1.5rem;
    .select {
      display: flex;
      justify-content: space-between;
      > div {
        width: 48%;
      }
    }
  }
  .save-btn {
    height: 2rem;
    margin-top: 1rem;
  }
  .save {
    float: right;
    bottom: 2rem;
    right: 2rem;
    width: 40px;
    cursor: pointer;
    background: #00957e;
    border-radius: 2px;
    color: #fff;
    padding: 6px 16px;
    text-align: center;
  }
}
.duration {
  margin-top: 0.3rem;
  display: flex;
  align-items: center;
}
.nanduDiv {
  display: flex;
  justify-content: center;
}
.nandu {
  padding: 0 6px;
  height: 23px;
  line-height: 23px;
  border-radius: 2px;
  // padding: 0 10px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
.type {
  display: flex;

  // justify-content: space-around;
  .type_left {
    div {
      width: 49px;
      padding: 0 6px;
      height: 23px;
      line-height: 23px;

      border-radius: 2px;
      // padding: 0 10px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .type_right {
    // width: 73px;
    padding: 0 6px;
    height: 23px;
    // background: #ffdf3e;
    border-radius: 2px;
    div {
      padding: 0 6px;
      height: 23px;
      line-height: 23px;
      border-radius: 2px;
      // padding: 0 10px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
.operate {
  margin: 2.2% 0 1.8%;
  display: flex;
  justify-content: flex-end;
  .new_problemList {
    margin-left: 1rem;
  }
}
.table-content {
  padding: 1rem;
  background-color: #fff;
}
.page {
  display: flex;
  justify-content: center;
  margin: 3% 0 1%;
}
::v-deep {
  .el-table__row {
    cursor: pointer;
  }
}
.dialogVisible {
  ::v-deep {
    .el-dialog__header {
      padding: 0;
    }
    .el-dialog__body {
      padding: 0;
    }
    .el-range__icon,
    .el-range-separator {
      line-height: 25px;
    }
    .el-input__inner {
      // border-radius: 40px;
      border: 1px solid #d4d4d4;
      height: 30px;
      line-height: 30px;
    }
    .el-table td,
    .el-table th.is-leaf {
      border-top: 1px solid #ebeef5;
      //  border-bottom: 1px solid #EBEEF5;
    }
    .el-upload--picture-card {
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      .el-icon-plus {
        color: #40b292;
        font-size: 22px;
        p {
          margin-top: 5px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
        }
      }
    }
    .hide .el-upload--picture-card {
      display: none;
    }
    .ql-container {
      height: 180px;
    }
  }
  .header {
    height: 35px;
    background: #00957e;
    color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
    line-height: 35px;
    align-items: center;
    .el-icon-circle-close {
      font-size: 20px;
      cursor: pointer;
    }
  }
}

.form {
  padding: 2% 5%;
  .rich {
    .tidan_title {
      color: #00957e;
      font-weight: 600;
      position: absolute;
      top: 4px;
      left: 160px;
    }
    .choice {
      position: relative;
      .choice-btn {
        font-size: 14px;
        min-width: 80px;
        cursor: pointer;
        text-align: center;
        line-height: 30px;
      }

      // display: flex;
      // justify-content: flex-end;
      margin-bottom: 2%;
      margin-left: 86px;
      .choice-btn {
        width: 80px;
        // border: 1px solid #00957e;
        height: 30px;
        margin: 0.5rem;
        padding: 0;
        background-color: #00957e;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        margin-left: 2%;
        border-radius: 2px;
        padding: 0 1%;
        min-width: 60px;
      }
    }
  }
  .btns {
    display: flex;
    justify-content: flex-end;
    div {
      text-align: center;
      cursor: pointer;
      border: 1px solid #00957e;
      // height: 30px;
      padding: 0;
      background-color: #00957e;
      font-size: 14px;
      line-height: 30px;
      color: #ffffff;
      margin-left: 2%;
      border-radius: 2px;
      padding: 0 1%;
      min-width: 60px;
    }
  }
}
// @import '../../../assets/css/bgc.css'
</style>