var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"problemList"},[_c('div',{staticClass:"content"},[_c('Breadcrumb'),_c('div',{staticClass:"box"},[_c('div',{staticClass:"content-left"},[_c('el-card',[_c('search-filter',{ref:"searchFilter",attrs:{"searchName":'搜索 比赛·考试·作业',"keywordOptions":{placeholder:'请输入比赛·考试·作业名称',prop:'keyword',autoName:'course',autocomplete:true},"searchOptions":[
                { name:'el-form-item' ,
                  options:{label:'难度' , prop:'diff_id', type:'radio-group'  , data:{ name:'question_other', keys:'diff_id' } , defaultValue :'全部'}
                },
                { name:'el-form-item' ,
                  options:{label:'来源' , prop:'type', type:'radio-group' , data:{ name:'question_other', keys:'type' } , defaultValue :'全部' }
                },
                // { name:'el-form-item' ,
                //   options:{label:'类型' , prop:'match_id', type:'radio-group' , data:{ name:'question_other', keys:'match' } , defaultValue :'全部' }
                // },
                // { name:'el-form-item' ,
                //   options:{label:'类别' , prop:'match_type', type:'radio-group' , data:{ name:'question_other', keys:'match_type' } , defaultValue :'全部' }
                // },
            ],"total":_vm.total},on:{"search":function($event){return _vm.currentChange(1)}},model:{value:(_vm.where),callback:function ($$v) {_vm.where=$$v},expression:"where"}}),_c('div',{staticClass:"operate"},[(_vm.userInfo.type === 2)?_c('el-button',{staticStyle:{"height":"30px"},attrs:{"type":"primary","size":"mini","plain":_vm.params.match_type === 1},on:{"click":_vm.showMymatch}},[_vm._v(" 我管理的比赛 ")]):_vm._e(),(_vm.userInfo.type === 2)?_c('el-button',{staticStyle:{"height":"30px"},attrs:{"type":"primary","size":"mini","plain":_vm.params.match_type === 1},on:{"click":_vm.showMycourse}},[_vm._v(" 我管理的考试 ")]):_vm._e(),(_vm.userInfo.type === 2)?_c('el-button',{staticStyle:{"height":"30px"},attrs:{"type":"primary","size":"mini","plain":_vm.params.match_type === 1},on:{"click":_vm.showMyexam}},[_vm._v(" 我管理的作业 ")]):_vm._e(),(_vm.userInfo.type=== 1)?_c('el-button',{staticStyle:{"height":"30px"},attrs:{"type":"primary","size":"mini"},on:{"click":_vm.mine}},[_vm._v(" 我参加的 ")]):_vm._e(),(_vm.userInfo.type=== 2)?_c('el-button',{staticStyle:{"height":"30px"},attrs:{"type":"primary","size":"mini"},on:{"click":_vm.establish}},[_vm._v(" 创建 ")]):_vm._e()],1)],1),_c('div',{staticClass:"operate"}),_c('div',{staticClass:"tableDatas"},[_c('Matchlist',{ref:"Matchlist",attrs:{"tableData":_vm.tableData,"params":_vm.params,"loading":_vm.loading}}),_c('div',{staticClass:"pagination"},[_c('el-pagination',{attrs:{"background":"","layout":"total,sizes,prev, pager, next","total":_vm.total,"page-size":_vm.pageSize,"current-page":_vm.page},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.currentChange}})],1)],1)],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }